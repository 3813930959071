import React from 'react';
import { twMerge } from 'tailwind-merge';

// export default function twStyled(Component, styles, config) {
//   const { as: SubComponent } = config || {};
//   // console.log("🚀 ~ file: twStyled.jsx ~ line 5 ~ twStyled ~ Component", Component.isTw)
//   const Result = (props) => {
//     const { tw, className, ...rest } = props;

//     if (SubComponent) {
//       return <SubComponent {...rest} className={twMerge(styles, className, tw)} />
//     }

//     return <Component {...rest} as={SubComponent} className={twMerge(styles, className, tw)} />
//   };

//   Result.isTw = true;

//   // substitute with component
//   Result.withComponent = (SubComponent) => {
//     return twStyled(Component, undefined, { as: SubComponent });
//     // const Result2 = (props) => {
//     //   const { tw, className, ...rest } = props;
//     //   return <Component {...rest} as={SubComponent} className={twMerge(styles, className, tw)} />
//     // }

//     // Result.isTw = true;

//     // return Result2;
//   };

//   Result.tw = (styles) => {
//     return twStyled(Result, styles);
//   };

//   return Result;
// }
export default function twStyled(Component, styles) {
  const Result = (props) => {
    const { tw, className, ...rest } = props;

    return <Component {...rest} className={twMerge(styles, className, tw)} />
  };

  Result._styles = Component._styles ? Component._styles.concat(styles) : styles;

  // substitute with component
  Result.withComponent = (SubComponent) => {

    const abc = Component._styles.concat(styles);

    const Result2 = (props) => {
      const { tw, className, ...rest } = props;
      return <SubComponent {...rest} className={twMerge(abc, className, tw)} />
    }

    Result2._styles = abc;

    return Result2;
  };

  Result.tw = (styles) => {
    return twStyled(Result, styles);
  };

  return Result;
}
