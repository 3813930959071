import React from "react"
import Container from "../components/Container";
import twStyled from "../twStyled";
import Footer from "./Footer";
import Header from "./Header";

export default function Layout({ children }) {
  return children;
}

const Main = twStyled(Container, ['flex-grow']).withComponent('main')
